import { defineStore } from "pinia";
import { useSsrFetch } from "../composables/useFetch";
import type { IAreaGuide, IAreaGuideCities, IAreaGuideCitiesResponse, IAreaGuideCountries } from "~/types/area-guide.types";

export const useGuidesStore = defineStore("guides", {
	state: () => ({
		countries: [] as IAreaGuideCountries[],
		guides: [] as IAreaGuideCities[],
        meta: {} as any,
	}),
	actions: {

        async fetchAreaGuidesHeaderMenus(): Promise<any> {
            const areaGuidesHeaderMenus = await useSsrFetch('pages/area-guide/header-menus');
            return areaGuidesHeaderMenus
        },

		async fetchAreaGuideCountries(): Promise<IAreaGuideCountries[]> {
            const areaGuideCountries = await useSsrFetch('pages/area-guide/countries') as any;
            this.countries = areaGuideCountries.data;
            this.meta = areaGuideCountries.meta;
            return areaGuideCountries;
        },

        async fetchAreaGuideCities(slug?: string): Promise<any> {
            const query = slug ? `slug=${slug}` : ''
            const areaGuide = await useSsrFetch(`pages/area-guide/cities?${query}`) as any;
            this.guides = areaGuide;
            return areaGuide
        },
	},
});
